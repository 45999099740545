const scrollTo = () => {
    const els = document.querySelectorAll("[data-scroll-to]");
    if (!els.length) return;
    let scrollToEl, id;

    els.forEach(el => {
        el.addEventListener("click", ({ currentTarget }) => {
            scrollToEl = document.querySelector(`#${ currentTarget.getAttribute("data-scroll-to") }`);
            if (!scrollToEl) return;
            scrollToEl.scrollIntoView({ behaviour: "smooth" });
        });
    });
}

document.addEventListener("DOMContentLoaded", scrollTo);

export default scrollTo;
