/**
 * Texture Sections
 * v1.0.0
 */
const textureSections = () => {
    const textureSectionEls = document.querySelectorAll("[data-texture-section]");
    if (!textureSectionEls.length) return;
    const sliderEl = document.querySelector("#texture-section-slider");
    if (!sliderEl) return;
    const htmlEl = document.querySelector("html");
    let activeId,
        curId,
        timeout;

    textureSectionEls.forEach(el => {
        const onChange = ({ currentTarget }) => {
            curId = currentTarget.getAttribute("data-texture-section");
            if (curId == activeId) return;
            activeId = curId;

            htmlEl.dispatchEvent(new CustomEvent("texture-section-update", { detail: {
                id: activeId,
            }}));

            if (sliderEl.matches("[data-void-scroll-event]")) return;
            sliderEl.querySelector(`[data-id="${activeId}"]`).click();
        };

        el.addEventListener("scroller-is-exiting--above", onChange);
        el.addEventListener("scroller-is-entering--above", onChange);
        el.addEventListener("scroller-is-offscreen--above", onChange);
    });

    // htmlEl.addEventListener("texture-section-update", ({ detail: { id }}) => {
    //     console.log("texture-section-update", id);
    // });

    const voidScrollEvent = () => {
        htmlEl.removeEventListener("mouseup", voidScrollEvent);
        sliderEl.setAttribute("data-void-scroll-event", "");
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            sliderEl.removeAttribute("data-void-scroll-event");
        }, 1000);
    };

    // TODO: REVIEW -- arrow key navigation events
    sliderEl.addEventListener("mousedown", () => {
        htmlEl.addEventListener("mouseup", voidScrollEvent);
    });

    sliderEl.addEventListener("active", ({ detail: { index, slide } }) => {
        setTimeout(() => {
            if (!sliderEl.matches("[data-void-scroll-event]")) return;
            const id = document.querySelector(`[aria-controls="${ slide.getAttribute("id") }"] > div`).getAttribute("data-id");
            document.querySelector(`#${id}`).scrollIntoView({ behavior: "smooth" });
        }, 100);
    });
}

document.addEventListener("DOMContentLoaded", textureSections);

export default textureSections;
