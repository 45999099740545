import {
    elToggleClasses,
} from "../utils/element-helpers";

/**
 * Intro Hero
 * 
 * Basic timeouts to control states then enable main content
 */
// const introHero = () => {
//     const htmlEl = document.querySelector("html");
//     const bannerEl = document.querySelector("[data-intro-hero]");


//     setTimeout(() => {
//         elToggleClasses(bannerEl, { "ani-show-title": true });

//         setTimeout(() => {
//             elToggleClasses(bannerEl, { "ani-show-plaster": true });

//             setTimeout(() => {
//                 elToggleClasses(htmlEl, {
//                     "initiating": false,
//                     "init": true,
//                 });

//                 setTimeout(() => {
//                     elToggleClasses(htmlEl, {
//                         "initiated": true,
//                     });
//                 }, 2000);
//             }, 500);
//         }, 1000);
//     }, 100);
// }
// const elToggleClasses = (element, classes) => {
//     Object.entries(classes).forEach(([className, state]) => {
//         element.classList.toggle(className, state);
//     });
// };
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const introHero = async () => {
    const htmlEl = document.querySelector("html");
    const bannerEl = document.querySelector("[data-intro-hero]");

    await delay(100);
    elToggleClasses(bannerEl, { "ani-show-title": true });

    await delay(1000);
    elToggleClasses(bannerEl, { "ani-show-plaster": true });

    await delay(500);
    elToggleClasses(htmlEl, { "initiating": false, "init": true });

    await delay(2000);
    elToggleClasses(htmlEl, { "initiated": true });
};

document.addEventListener("DOMContentLoaded", introHero);

export default introHero;
