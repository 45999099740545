import {
    elToggleClasses,
} from "../utils/element-helpers";

/**
 * Formie injected content
 */
const formie = () => {
    // Replace submit button content
    document.querySelectorAll(".fui-submit").forEach(el => {
        elToggleClasses(el, {
            "button": true,
            "btn-arrow": true,
        });

        el.innerHTML = `   
            <span class="icon icon-solid-arrow-corner-right button__icon">
                <svg><use xlink:href="#solid/arrow-corner-right"></use></svg>
            </span>

            <div class="button__text">
                ${el.textContent}
            </div>
        `;

        el.addEventListener("click", ({ currentTarget }) => {
            // Formie disables after click, but then isn't restored.
            setTimeout(() => {
                currentTarget.removeAttribute("disabled");
            }, 100);
        });
    });
}

document.addEventListener("DOMContentLoaded", formie);

export default formie;
