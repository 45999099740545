import Splide from "@splidejs/splide";
// import "@splidejs/splide/css/core"; // Imported in vendor
import {
    elSetCSS,
    elGetParents,
} from "../utils/element-helpers";

/**
 * Splide
 * v1.1.0
 */
const splide = () => {
    let splideEls,
        paginationEl,
        contentEls,
        focusEl,
        id,
        instance,
        options,
        optionsOverride;

    const initSplide = (type, optionsDefault) => {
        splideEls = document.querySelectorAll(`.splide--${type}`);
        if (!splideEls.length) return;

        splideEls.forEach((el, index) => {
            id = `${type}-${index}`;
            el.setAttribute("data-splide-id", id);

            optionsOverride = JSON.parse(el.getAttribute("data-config") ?? null);
            options = { ...optionsDefault, ...optionsOverride };
            el.removeAttribute("data-config");

            instance = new Splide(`[data-splide-id="${id}"]`, options);

            if (options.pagination) {
                paginationEl = el.querySelector("[data-splide-pagination]");
                contentEls = paginationEl.querySelectorAll("li");

                instance.on("pagination:mounted", (data) => {
                    data.items.forEach((item) => {
                        item.button.innerHTML = contentEls[item.page].innerHTML;
                    });
                });

                paginationEl.remove();

                if (options.paginationProgress) {
                    instance.on("autoplay:playing", rate => {
                        elSetCSS(el, {
                            "--rate": rate,
                        });
                    });
                }

                if (options.onActive) {
                    instance.on("active", e => {
                        if (e.isClone) return;
                        elGetParents(e.slide, ".splide")[0].dispatchEvent(new CustomEvent("active", { detail: {
                            index: e.index,
                            slide: e.slide,
                        }}));
                    });
                }
            }

            // TODO: Trigger slide instance change
            el.querySelectorAll("[data-slide-trigger]").forEach(slideTriggerEl => {
                slideTriggerEl.addEventListener("click", ({ currentTarget }) => {
                    const index = parseInt(currentTarget.getAttribute("data-slide-trigger")) - 1;
                    const button = el.querySelector(".splide__pagination").children[index].querySelector("button");
                    button.click();
                });
            });

            // Blur any focused child element on mouse interaction
            el.addEventListener("mouseover", ({ currentTarget }) => {
                focusEl = currentTarget.querySelector(":focus");
                if (focusEl) focusEl.blur();
            });
            // Patch - Remove focus of other siblings on hover to prevent multiple visible hover appearances
            // Specific solution, but general above should be suffice
            // el.querySelectorAll(".splide__slide").forEach(slideEl => {
            //     slideEl.addEventListener("mouseover", ({ currentTarget }) => {
            //         elGetSiblings(currentTarget).forEach(siblingEl => {
            //             siblingEl.querySelector("a:focus").blur();
            //         });
            //     });
            // })

            instance.mount();
        });
    }

    // Initiate Splides
    initSplide("default", {
        focus: "left",
        type: "loop",
        updateOnMove: true,
        pagination: false,
        rewind: true,
        rewindSpeed: 0,
        rewindByDrag: true,
    });

    initSplide("center", {
        focus: "center",
        type: "loop",
        updateOnMove: true,
        pagination: false,
        rewind: true,
        rewindSpeed: 0,
        rewindByDrag: true,
    });

    initSplide("tabs", {
        type: "loop",
        autoplay: true,
        updateOnMove: true,
        pagination: true,
        rewind: true,
        rewindSpeed: 0,
        rewindByDrag: true,
        paginationProgress: true,
    });

    initSplide("home", {
        focus: "left",
        type: "loop",
        perPage: 3,
        autoplay: false,
        updateOnMove: true,
        gap:20,
        pagination: false,
        rewind: true,
        rewindSpeed: 0,
        rewindByDrag: true,
        paginationProgress: true,
        breakpoints: {
            800: {
                perPage: 2,
            },
            500: {
                perPage: 1
            },
      }
    });

    // initSplide("foo", {
    //     // options
    // });
}

document.addEventListener("DOMContentLoaded", splide);

export default splide;
